/*
 * Slate operation attributes.
 */

const OPERATION_ATTRIBUTES = {
    add_mark: ['value', 'path', 'offset', 'length', 'mark'],
    insert_node: ['value', 'path', 'node'],
    insert_text: ['value', 'path', 'offset', 'text', 'marks'],
    merge_node: ['value', 'path', 'position', 'properties', 'target'],
    move_node: ['value', 'path', 'newPath'],
    remove_mark: ['value', 'path', 'offset', 'length', 'mark'],
    remove_node: ['value', 'path', 'node'],
    remove_text: ['value', 'path', 'offset', 'text', 'marks'],
    set_mark: ['value', 'path', 'offset', 'length', 'mark', 'properties'],
    set_node: ['value', 'path', 'node', 'properties'],
    set_selection: ['value', 'selection', 'properties'],
    set_value: ['value', 'properties'],
    split_node: ['value', 'path', 'position', 'properties', 'target']
};

export default OPERATION_ATTRIBUTES;
