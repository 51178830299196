// Inverted index types
export type Key = string;
export type PathIndex = number;
export type Path = PathIndex[];

// Index entries
export interface Entry {
    // Maybe track key later ?
    // key: Key,
    index: PathIndex; // Index relative to it's direct parent
    parentKey: Key; // The node's directy parent
}
export type Pair = [Key, Entry];

// IndexLike describes the general behavior of a path lookup index
// it's API is based on Map<Key, Path> (for now)
// but we define it as an interface instead of a type alias
// to avoid conflicts with immutable.Map
interface IndexLike {
    get(key: Key): Path | undefined;
    keys(): IterableIterator<Key>;
}

/*
 * NOTE: this is a utility method that will probably disappear
 * and be replaced by the next-gen LookupIndex's constructor
 */
export class LookupIndex {
    private ownerKey: Key; // The node that "owns" this LookupIndex
    private m: Map<Key, Entry>;

    constructor(ownerKey: Key, pairs: Pair[][]) {
        this.ownerKey = ownerKey;
        this.m = new Map();
        for (const ps of pairs) {
            for (const p of ps) {
                this.m.set(p[0], p[1]);
            }
        }
    }

    public get(key: Key): Path | undefined {
        const p: Path = [];
        let currentKey = key;
        // Start at the provided key and follow the parent's till we reach the owner node
        while (currentKey !== this.ownerKey) {
            const entry = this.m.get(currentKey);
            if (!entry) {
                return undefined;
            }
            currentKey = entry.parentKey;
            p.unshift(entry.index); // Build path in reverse
        }
        return p;
    }

    public keys(): IterableIterator<Key> {
        return this.m.keys();
    }
}

/*
 * Path methods
 * NOTE: these are not yet used today, but will be used in future
 * iterations of the LookupIndex and node-factory
 */

function pathCompare(a: Path, b: Path): number {
    const commonLength = Math.min(a.length, b.length);
    // Compare common parts of paths and exit if we see a non equality
    for (let i = 0; i < commonLength; i++) {
        if (a[i] > b[i]) {
            return 1;
        } else if (a[i] < b[i]) {
            return -1;
        }
    }
    // Paths are equal
    if (a.length === b.length) {
        return 0;
    }
    // Common parts are equal, so the longer path is ahead of the other
    return a.length > b.length ? 1 : -1;
}

export function isPathEqual(a: Path, b: Path): boolean {
    return pathCompare(a, b) === 0;
}

export function isPathBefore(a: Path, b: Path): boolean {
    return pathCompare(a, b) === -1;
}

export function isPathAfter(a: Path, b: Path): boolean {
    return pathCompare(a, b) === 1;
}
